.navbars {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    width: 100%;

  }
  .navHomePage{
    position: absolute;
    background-color: transparent;
  }
  
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;

  }
  
  .nav-logo {

    width: 70px;
    display: flex;

  }
  .nav-logo img{
    width: 100%;
    height: 100%;

  }
 
  
  .nav-items {
    display: flex;
    gap: 1rem;
    list-style: none;
  }
  
  .nav-items a {
    text-decoration: none;
    color: #ffff;
    font-size: 1.1rem;
    transition: color 0.3s ease;
  }
  
  .nav-items a:hover {
    color: #007bff;
  }
  
  .nav-toggle {
    position: absolute;
    right: 1em;
    
    display: none;
    z-index: 1;
    cursor: pointer;
    flex-direction: column;
    gap: 5px;

    margin: 0;
    
  }
  

  .signoutBtn{
    width: 90px;
    padding: 5px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;

  }
  @media (max-width: 768px) {
    .nav-toggle {
      display: flex;

    }
    .nav-logo {
     margin-left: 1em;
     
    }
      
    .nav-items a {
        color: black;
    }
    .nav-container {
        margin: 0;
        
    }
    .navlinkss{
      margin: 0;
      padding: 0;
    }
    .nav-items {
    
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      width: 100px;
      flex-direction: column;
      background-color: #fff;

      transition: transform 0.3s ease;
      transform: translateX(100%);
      padding-top: 3rem;
      align-items: center;
      gap: 30px;
      z-index: 9;
    }
    .nav-toggles {
       position: absolute;
       right: 4.5em;
       top: 0.4em;
       z-index: 99;
    }
    .nav-items.open {
      transform: translateX(0);
    
    }
  }