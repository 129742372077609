.homePageShowCaseDiv{
    width: 90%;
    height: 300px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 25px;
}
.showCaseInfo {

}
.showCaseInfo h1{
    color: #2196f3; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); 
    margin: 0;
    padding: 0;
}
.showCaseInfo p{
    color: #000000; /* Black color */
    text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff; 
    margin: 0;
    padding: 0;
}

.discoverBtn button{
    width: 170px;
    padding: 5px;
    font-size: 1.10rem;
    cursor: pointer;
    background-color: #008afc;
    border: none;
    color: white;
}