.propertiesContainer {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: rgb(12, 17, 87);


}

.propertiesContent {

    width: 90%;

    margin: 0 auto;
    display: flex;
    padding-top: 10em;
    gap: 30px;
   

}

/* filter section*/
.searchFilter {
    display: flex;

    flex-direction: column;

    gap: 30px;


}

.propertiesContent h3 {


    text-align: center;
    padding: 0;
    font-size: 1.5rem;
    color: white;
}

.searchFilter input {
    font-size: 1.1rem;
    text-align: center;
    height: 25px;
    border: none;
}

/*Filter dropdown */

.propertDropdown {
    position: relative;
    display: inline-block;

}

.dropbtn {
    background-color: #9C27B0;
    color: white;
    padding: 10px;
    width: 220px;
    font-size: 16px;
    border: 1px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

}

.dropdown-content {
    display: none;
    position: relative;
    background-color: #f9f9f9;
    min-width: 160px;
    padding-bottom: 5px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
    transform: translateY(-20px);
}

.dropdown-content.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.dropdown-content ul {
    color: black;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dropdown-content ul li:hover {
    cursor: pointer;
}

/*Filter drop down ends here*/
/* filter ends*/


/*Property showcase*/

.propertyShowCase {

    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    height: fit-content;
    gap: 4px;
}


.selectedFilter{
    border-bottom: 1px solid #9C27B0 ;
}
/*searchForPropText*/
.searchForPropText {
    margin: 0;

    position: absolute;
    margin-top: 1em;
    color: white;
    width: 100%;
    text-align: center;
    align-items: center;
  
    display: flex;
    flex-direction: column;
    font-size: 23px;
    gap: 13px;
}
.searchForPropText p{
    margin: 0;
}
.searchForPropText input{
    width: 260px;
    border: 33%;
    height: 30px;
}
@media (max-width:1250px) {
    .propertyShowCase {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width:940px) {
    .propertyShowCase {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width:940px) {
    .propertiesContent {
        flex-direction: column;
    }

    .dropdown-content {
        position: absolute;
    }

    .searchFilter {
        flex-direction: row;

        justify-content: space-between;
        gap: 10px;
    }

    .propertyShowCase {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width:810px) {

    .dropbtn {
        width: 150px;
    }

    .propertyShowCase {
        grid-template-columns: repeat(2, 1fr);
    }

    .property-cards {
        width: 250px;
    
    }
}

@media (max-width:572px) {
    .propertiesContent {
        width: 100%;
        width: 96%;
        margin: 0 auto;
    }

    .dropbtn {
        width: 110px;
        font-size: 14px;
    }


    .propertyShowCase {
        width: 96%;
        margin: 0 auto;

        align-self: center;
    }

    .property-cards {
        width: 230px;
    }

}

@media (max-width:500px) {
    .property-cards {
        width: 190px;
    }
}

@media (max-width:390px) {
    .property-cards {
        width: 170px;
    }
}