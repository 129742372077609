.singlePropertyContainer {
    background-color: rgb(12, 17, 87);
   
    width: 100%;
    min-height: 100vh;
    display: flex;
    
    justify-content: center;
 
}

.singlePropertyContent {
    color: white;
    min-height: 400px;
    height: fit-content;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 3em;
}

.singlePropertyImages {
    width: 50%;
    position: relative;

}

.singlePropertyImages img {
    width: 100%;
    height: auto;
   
    height: 400px;
    object-fit: cover;
}

.imageNav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.imageNav button {
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.propertyDetails {
    width: 50%;
    padding: 20px;
    position: relative;
}

.whatsAppDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.whatsapp-button {
   
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:darkred;

    color: white;
    padding: 12px 18px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    transition: background 0.3s ease-in-out;
    width: fit-content;
  
  }
  
  .whatsapp-button:hover {
    background-color: #1ebc57;
  }
  

@media (max-width: 768px) {
    .singlePropertyContent {
        width: 90%;
    }
    .singlePropertyContent {
        flex-direction: column;
        align-items: center;
    }

    .singlePropertyImages, .propertyDetails {
        width: 100%;
    }
}
