.aboutMeContainer {
  background-color: rgb(12, 17, 87);
    color: white;
    padding: 50px;
    text-align: center;
    min-height: 100vh;
  }
  
  .aboutMeContent {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
  
  .aboutMeContent h1 {
    margin-bottom: 20px;
  }
  
  .aboutMeContent p {
    line-height: 1.6;
  }
  