.dashboard-containers {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
   margin-top: 2em;
 
 
   min-height: 100vh;
}

.dashboard-linkss {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashboard-linkss li {
    margin-bottom: 15px;
}

.manage-dashboard-link {
    display: block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
}

.manage-dashboard-link:hover {
    background-color: #0056b3;
}
