.card-container{
    width: 100vw;
    height: fit-content;
    min-height: 500px;
    background-color: #f0f0f0ad;
    display: flex;
    justify-content: space-around;
   
    padding-top: 10em;
    gap: 20px;
}
.card {
    width: 300px;  
    max-height: 300px;
    margin: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }

  .card:hover {
    transform: translateY(-10px);
  }
  
  .icon {
    margin-bottom: 20px;
  }

  .discover-link {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease-in-out;
  }
  
  .discover-link:hover {
    color: #0056b3;
  }
  .icon img {
    width: 50%;  
    max-width: 100px;  
    height: auto;  
  }
  @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
      align-items: center;
      
    }
  
    .card {
      width: 80%;  
    }
   
  .icon img {
    width: 30%;  
    max-width: 80px;  
  }
  }