.footerContainer {
    background-color: #040d2c;
    color: white;
    padding: 20px 0;
    text-align: center;
    padding-top: 2em;
    
    width: 100%;
  }
  
  .footerContent {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: space-around;

    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
  }
  
  .footerLogoSection {
    display: flex;
    flex-direction: column;
    align-items: center;

   padding: 15px;
  }
  .footerLogoSection img {
    width: 100px;
  }
  .footerSection {
    flex: 1;
    min-width: 200px;
    margin: 20px;
  }
  
  .footerSection h3 {
    margin-bottom: 10px;
  }
  
  .footerSection p {
    margin: 5px 0;
  }
  
  .footerSection a {
    color: #27a844;
    text-decoration: none;
  }
  
  .footerSection a:hover {
    text-decoration: underline;
  }
  

  @media (max-width:1000px){
    .footerContent {
      grid-template-columns: repeat(1,1fr);
    }
  }